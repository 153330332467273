import React from 'react';
import { Link as LinkScroll } from 'react-scroll'


const Nav = () => {
  return (
    <nav className='mainmenu-nav'>
      <ul className='mainmenu'>
        <li className='menu-item-has-children'>
          <LinkScroll activeClass='active' spy={true} smooth={true} offset={0} duration={1000} to='home'>
            Home
          </LinkScroll>

        </li>
        <li className='menu-item-has-children'>
          <LinkScroll activeClass='active' spy={true} smooth={true} offset={0} duration={1000} to='about'>
            About
          </LinkScroll>

        </li>
        <li className='menu-item-has-children'>
          <LinkScroll activeClass='active' spy={true} smooth={true} offset={0} duration={1000} to='services'>
            Services
          </LinkScroll>

        </li>
        <li>
          <LinkScroll activeClass='active' spy={true} smooth={true} offset={0} duration={1000} to={'contact'}>Contact</LinkScroll>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
