import React from 'react';
import Logo from '../../elements/logo/Logo';
import Nav from '../../common/header/Nav';
import StickyHeader from './StickyHeader';
import MobileMenu from './MobileMenu';

const HeaderOne = () => {


  const sticky = StickyHeader(100);

  const MobileMenuHandler = () => {
    document.querySelector('.mobilemenu-popup').classList.toggle('show');
    document.querySelector('body').classList.toggle('mobilemenu-show');
  };

  return (
    <>
      <header className='header axil-header header-style-1' id='home'>
        <div className={`axil-mainmenu ${sticky ? 'axil-sticky' : ''}`}>
          <div className='container'>
            <div className='header-navbar'>
              <div className='header-logo'>
                <Logo
                  limage='/images/black.svg'
                  dimage='/images/white.svg'
                  simage='/images/black.svg'
                />
              </div>
              <div className='header-main-nav'>
                <Nav />
              </div>
              <div className='header-action'>
                <ul className='list-unstyled'>
                  <li className='mobile-menu-btn sidemenu-btn d-lg-none d-block'>
                    <button className='btn-wrap' onClick={MobileMenuHandler}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileMenu MobileHandler={MobileMenuHandler} />
    </>
  );
};

export default HeaderOne;
