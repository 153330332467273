import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';

// Home Pages Import
import DigitalAgency from './pages/DigitalAgency';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

// Css Import
import './assets/scss/app.scss';
import ErrorPage from './pages/404';

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route
            path={process.env.PUBLIC_URL + '/'}
            element={<DigitalAgency />}
          />
          <Route
            path={process.env.PUBLIC_URL + '/privacy-policy'}
            element={<PrivacyPolicy />}
          />
          <Route
            path={process.env.PUBLIC_URL + '/terms-use'}
            element={<TermsOfUse />}
          />

          <Route
            path={process.env.PUBLIC_URL + '*'}
            element={<ErrorPage />}
          />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
