import React from 'react';
import { Link as LinkScroll } from 'react-scroll'

const Logo = ({ limage, dimage, simage }) => {
  return (
    <LinkScroll to={'home'} activeClass='active' spy={true} smooth={true} offset={0} duration={1000} >
      <img
        className='light-version-logo'
        src={process.env.PUBLIC_URL + limage}
        alt='logo'
        style={{ maxWidth: '100%', width: '200px' }}
      />
      <img
        className='dark-version-logo'
        src={process.env.PUBLIC_URL + dimage}
        alt='logo'
        style={{ maxWidth: '100%', width: '200px' }}
      />
      <img
        className='sticky-logo'
        src={process.env.PUBLIC_URL + simage}
        alt='logo'
        style={{ maxWidth: '100%', width: '200px' }}
      />
    </LinkScroll>
  );
};

export default Logo;
