import React from 'react';

const AboutOne = () => {
  return (
    <section className='section section-padding-equal bg-color-dark' id='about'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='about-us'>
              <div className='section-heading heading-left mb-0'>
                <span className='subtitle'>About Us</span>
                <h2 className='title mb--40'>Who We Are</h2>
                <p>
                  EonBix IT Solutions is dedicated to delivering cutting-edge software solutions that surpass client expectations. Our skilled team utilizes streamlined processes and advanced technologies to redefine software development. Specializing in information management outsourcing, we empower businesses across various industries to achieve their technology goals. Our talented engineers and project managers provide high-quality, cost-effective solutions, converting technology talent overhead into flexible, scalable expenditures.
                </p>
                <h3 className='title mb--20'>Mission</h3>
                <p>
                  Our mission is to consistently deliver exceptional software solutions through a combination of top-tier talent, innovative processes, and advanced technologies.
                </p>

              </div>
            </div>
          </div>

          <div className='col-xl-5 col-lg-6 offset-xl-1'>
            <div className='heading-left'>
              <h3 className='title mb--20'>Vision</h3>
              <p>
                To be a global leader in software development, renowned for delivering impactful and innovative solutions that reshape the digital landscape.
              </p>
              <h3 className='title mb--20'>Our Values</h3>
              <p>
                To be a global leader in software development, renowned for delivering impactful and innovative solutions that reshape the digital landscape.</p>
              <div className='list-group'>
                <p className='mb--20'><strong>Excellence:</strong> Delivering top-quality software solutions that exceed industry standards.</p>
                <p className='mb--20'><strong>Innovation:</strong> Driving technological advancements to keep our clients ahead in the digital era.</p>
                <p className='mb--20'><strong>Craftsmanship:</strong> Ensuring robust, scalable, and elegant software solutions through meticulous development.</p>
                <p className='mb--20'><strong>Collaboration:</strong> Partnering with clients to co-create and refine software products, fostering strong, collaborative relationships.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className=' list-unstyled shape-group-10'>
        <li className='shape shape-1'>
          <img
            src={process.env.PUBLIC_URL + '/images/others/line-9.png'}
            alt='Circle'
          />
        </li>
        <li className='shape shape-2'>
          <img
            src={process.env.PUBLIC_URL + '/images/others/bubble-42.png'}
            alt='Circle'
          />
        </li>
        <li className='shape shape-3'>
          <img
            src={process.env.PUBLIC_URL + '/images/others/bubble-43.png'}
            alt='Circle'
          />
        </li>
      </ul>
    </section>
  );
};



export default AboutOne;
