import React from 'react';
import { FaTimes } from 'react-icons/fa';
import Nav from './Nav';
import { Link as LinkScroll } from 'react-scroll'

const MobileMenu = ({ MobileHandler }) => {
  return (
    <div className='mobilemenu-popup'>
      <div className='mobilemenu-inner'>
        <div className='mobilemenu-header'>
          <div className='mobile-nav-logo'>
            <LinkScroll to='home' activeClass='active' spy={true} smooth={true} offset={0} duration={1000} >
              <img
                className='light-mode'
                src={process.env.PUBLIC_URL + '/images/black.svg'}
                alt='Site Logo'

              />
              <img
                className='dark-mode'
                src={process.env.PUBLIC_URL + '/images/white.svg'}
                alt='Site Logo'

              />
            </LinkScroll>
          </div>
          <button className='mobile-menu-close' onClick={MobileHandler}>
            <FaTimes />
          </button>
        </div>
        <div className='mobilemenu-body'>
          <Nav />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
