import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import BannerOne from '../component/banner/BannerOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import Contact from './Contact';


const DigitalAgency = () => {
  return (
    <>
      <SEO title='Eonbix IT Solutions' />
      {/* <ColorSwitcher /> */}
      <main className='main-wrapper'>
        <HeaderOne />
        <BannerOne />
        <AboutOne />
        <div className='section section-padding-2 bg-color-light' id='services'>
          <div className='container'>
            <SectionTitle
              subtitle='What We Can Do For You'
              title='Services we can help you with'
              description='We deliver innovative, scalable software development and integration services, leveraging cutting-edge technologies to meet diverse client needs across various industries.'
              textAlignment='heading-light-left'
              textColor='color-text-dark'
            />
            <div className='row'>
              <ServicePropOne
                colSize='col-xl-4 col-md-6'
                serviceStyle=''
                itemShow='6'
              />
            </div>
          </div>
          <ul className='shape-group-6 list-unstyled'>
            <li className='shape shape-1'>
              <img
                src={process.env.PUBLIC_URL + '/images/others/bubble-7.png'}
                alt='Bubble'
              />
            </li>
            <li className='shape shape-2'>
              <img
                src={process.env.PUBLIC_URL + '/images/others/line-4.png'}
                alt='line'
              />
            </li>
            <li className='shape shape-3'>
              <img
                src={process.env.PUBLIC_URL + '/images/others/line-5.png'}
                alt='line'
              />
            </li>
          </ul>
        </div>

        {/* <CtaLayoutOne /> */}
        <Contact />
        <FooterOne parentClass='' />
      </main>
    </>
  );
};

export default DigitalAgency;
